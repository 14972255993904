import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import PersonalLoansIllustration from "../../../svg/ComparisonResultPageIllustrations/personalloan.svg";
import AvantSVG from "../../../svg/ComparisonResultGridLogos/personalloans/avant.svg";
import BestEggSVG from "../../../svg/ComparisonResultGridLogos/personalloans/bestegg.svg";
import UpstartSVG from "../../../svg/ComparisonResultGridLogos/personalloans/upstart.svg";
import PropsperSVG from "../../../svg/ComparisonResultGridLogos/personalloans/prosper.svg";
import PayoffSVG from "../../../svg/ComparisonResultGridLogos/personalloans/payoff.svg";
export const pageMetaData = {
  pageTitle: "Compare Personal Loans",
  pageDescription: "Variable APR From 5.49%",
  pageImagePath: "/personalloans.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "APR From"
      }, {
        index: 2,
        text: "Minimum Credit Score"
      }, {
        index: 3,
        text: "Origination Fee"
      }, {
        index: 4,
        text: "Late Fee"
      }, {
        index: 5,
        text: "Available Terms"
      } // {
      //   index: 6,
      //   text: "Qualification",
      // },
      // {
      //   index: 7,
      //   text: "Pros",
      // },
      // {
      //   index: 8,
      //   text: "Cons",
      // },
      ],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Upstart",
          subText: "",
          imageKey: "upstart"
        }
      }, {
        index: 2,
        text: "8.13%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "620"
      }, {
        index: 4,
        text: "0 - 8%"
      }, {
        index: 5,
        text: "$15"
      }, {
        index: 6,
        text: "3 - 5 Years"
      } // {
      //   index: 7,
      //   usePopover: true,
      //   popOverTitle: "Qualification",
      //   popOverContents:
      //     "Minimum credit score of 660.|Enough income to pay existing debts and a new LightStream loan.|Maximum debt-to-income ratio varies depending on existing assets and the reason for the loan.",
      // },
      // {
      //   index: 8,
      //   usePopover: true,
      //   popOverTitle: "Pros",
      //   popOverContents: "No fees.|Rate discount for autopay.|Low starting rates.",
      // },
      // {
      //   index: 9,
      //   usePopover: true,
      //   popOverTitle: "Cons",
      //   popOverContents: "Does not offer pre-qualification on its website.|Requires several years of credit history.",
      // },
      ],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/upstart-pl/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=upstart_pl&utm_content=",
        openLinkExternally: true,
        text: "Get My Rate",
        title: "Click to sign up to Upstart",
        subText: "",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Avant",
          subText: "",
          imageKey: "avant"
        }
      }, {
        index: 2,
        text: "9.95%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "580"
      }, {
        index: 4,
        text: "Up to 4.75%"
      }, {
        index: 5,
        text: "$25"
      }, {
        index: 6,
        text: "2 - 5 Years"
      } // {
      //   index: 7,
      //   usePopover: true,
      //   popOverTitle: "Qualification",
      //   popOverContents: "Minimum credit score: 680, but typically 700 or higher.|Minimum annual income: None; borrowers' average is over $100,000.",
      // },
      // {
      //   index: 8,
      //   usePopover: true,
      //   popOverTitle: "Pros",
      //   popOverContents: "Soft credit check with pre-qualify.|Flexible payment options.|Offers member perks.",
      // },
      // {
      //   index: 9,
      //   usePopover: true,
      //   popOverTitle: "Cons",
      //   popOverContents: "Does not offer direct payment to creditors with debt consolidation loans.|Does not offer refinance options.",
      // },
      ],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/avant-pl/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=avant_pl&utm_content=",
        openLinkExternally: true,
        text: "Get My Rate",
        title: "Click to sign up to Avant",
        subText: "",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Best Egg",
          subText: "",
          imageKey: "best-egg"
        }
      }, {
        index: 2,
        text: "5.999%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showRating: true,
        rating: 5
      }, {
        index: 3,
        text: "640"
      }, {
        index: 4,
        text: "0.99 - 5.99%"
      }, {
        index: 5,
        text: "$15"
      }, {
        index: 6,
        text: "3 - 5 Years"
      } // {
      //   index: 7,
      //   usePopover: true,
      //   popOverTitle: "Qualification",
      //   popOverContents:
      //     "At least 18 years old (19 in Alabama; 21 in Mississippi and Puerto Rico).|Valid U.S. bank account, Social Security number or tax ID.|680+ credit score.|For debt consolidation loans, you may be required to send part of the loan directly to your creditors.",
      // },
      // {
      //   index: 8,
      //   usePopover: true,
      //   popOverTitle: "Pros",
      //   popOverContents: "No fees.|Flexible payment options.|Directly pays creditors for debt consolidation loans.|Rate discount with autopay.",
      // },
      // {
      //   index: 9,
      //   usePopover: true,
      //   popOverTitle: "Cons",
      //   popOverContents: "No option to include co-signer.|Reports payments to one of the three major credit bureaus.",
      // },
      ],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/bestegg-pl/?utm_source=Top5&utm_medium=referral&utm_campaign=bestegg_pl&utm_content=",
        openLinkExternally: true,
        text: "Get My Rate",
        title: "Click to sign up to Best Egg",
        subText: "",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Prosper",
          subText: "",
          imageKey: "prosper"
        }
      }, {
        index: 2,
        text: "7.95%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "640"
      }, {
        index: 4,
        text: "0% - 5%"
      }, {
        index: 5,
        text: "None"
      }, {
        index: 6,
        text: "3 - 5 Years"
      } // {
      //   index: 7,
      //   usePopover: true,
      //   popOverTitle: "Qualification",
      //   popOverContents:
      //     "Minimum credit score: 640, but average is 717.|Minimum credit history: Two years, but average is 11.|Minimum annual income: None, but average is $89,000.|Maximum debt-to-income ratio: 50% (excluding mortgage).|No bankruptcies filed within the last year.|Fewer than five credit bureau inquiries in the last six months.",
      // },
      // {
      //   index: 8,
      //   usePopover: true,
      //   popOverTitle: "Pros",
      //   popOverContents: "No prepayment fee.|Option to change your payment date.|Offers joint loan option.",
      // },
      // {
      //   index: 9,
      //   usePopover: true,
      //   popOverTitle: "Cons",
      //   popOverContents: "Charges origination fee.|Charges late fee of $15 or 5% of the unpaid loan amount.",
      // },
      ],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://prosper.evyy.net/c/1323808/631249/994",
        openLinkExternally: true,
        text: "Get My Rate",
        title: "Click to sign up for Prosper",
        subText: "",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Payoff",
          subText: "",
          imageKey: "payoff"
        }
      }, {
        index: 2,
        text: "5.99%",
        disableDollarPrefix: true,
        isPriceCell: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "640"
      }, {
        index: 4,
        text: "0% - 5%"
      }, {
        index: 5,
        text: "None"
      }, {
        index: 6,
        text: "2 - 5 Years"
      } // {
      //   index: 7,
      //   usePopover: true,
      //   popOverTitle: "Qualification",
      //   popOverContents: "Minimum credit score of 640.|Minimum credit history: 3 years.|Minimum annual income: $40,000.|Debt-to-income ratio: less than 50%.",
      // },
      // {
      //   index: 8,
      //   usePopover: true,
      //   popOverTitle: "Pros",
      //   popOverContents: "Competitive rates among similar lenders.|Offers online educational resources.|Offers direct payment to creditors with debt consolidation loans.",
      // },
      // {
      //   index: 9,
      //   usePopover: true,
      //   popOverTitle: "Cons",
      //   popOverContents: "No co-sign or secured loan option.|Charges origination fee.",
      // },
      ],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.credible.com/a/payoff-pl/?utm_source=comparisoncompany&utm_medium=referral&utm_campaign=payoff_pl&utm_content=",
        openLinkExternally: false,
        text: "Get My Rate",
        title: "Click to sign up to Payoff",
        subText: "",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare personal loan products`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<PersonalLoansIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="PersonalLoansIllustration" />, <PersonalLoansIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="PersonalLoansIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Personal loans are one of the most flexible financial tools around. A personal loan can help you keep your finances streamlined with one single monthly payment at low fixed-rate interest from big purchases to consolidating debt. When it comes time to apply for a personal loan, it's crucial to compare loans and loan companies to get the best deal for your needs.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Personal Loan Products" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <AvantSVG className="w-full" imagekey="avant" mdxType="AvantSVG" />
  <BestEggSVG className="w-full" imagekey="best-egg" mdxType="BestEggSVG" />
  <UpstartSVG className="w-full" imagekey="upstart" mdxType="UpstartSVG" />
  <PropsperSVG className="w-full" imagekey="prosper" mdxType="PropsperSVG" />
  <PayoffSVG className="w-full" imagekey="payoff" mdxType="PayoffSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="loans" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked and Compared Loan Providers`}</h2>
        <h3>{`Fixed-Rate APR`}</h3>
        <p>{`APR is essentially the interest rate assigned to any loan amount. You'll see APR on everything from a basic credit card to your mortgage. For personal loans, it's best if APRs have a low fixed-rate that won't change for the term of the loan. Finding a loan with a low, fixed-rate APR means you will not be paying a large amount of interest on the loan, which can save money. SoFi and LightStream, for example, have the lowest APRs on this list, which means a $5000 loan will only have a small amount of interest to be paid: usually less than $1000.`}</p>
        <h3>{`Repayment Plans`}</h3>
        <p>{`Repayment plans refer to the monthly payments of your loan term. For repayment plans, we looked at both how long the loan terms were and if the repayment plans were fixed. The length of the loan term determines how many months you will be paying off the loan, while fixed monthly payments mean it's easier to budget your finances around a predictable monthly payment. Both Marcus by Goldman Sachs and Best Egg have fixed monthly payments.`}</p>
        <h3>{`Credit Score Eligibility`}</h3>
        <p>{`Your credit score determines the ability to get many loans, so one key factor we looked at was the credit score eligibility requirements. Some loans on this list require good or excellent credit scores above the 700 range, while other loans do not require a credit history at all. In this way, there is certainly a personal loan that has credit score eligibility right for every financial situation, such as Upstart if you have no credit history, or Best Egg if your credit score is at least 700.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="Personal Loans" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Personal loans are a versatile tool that can help you accomplish many goals. Whether you need to make a big purchase, such as house renovations, or you need to consolidate credit or medical debt, `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{` will help you find the right personal loan to give you the opportunity to take control of your finances.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      